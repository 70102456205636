import AgeGroupPanel from "./AgeGroupPanel";

const GroupPanel = ({ data, index, activegroup, ageGroup, handleAgeGroup, subAgeGroup, handleSubAgeGroup, open, setOpen, size, resizeFunction }) => {
  const ageToggleData = [];

  if (ageGroup === "20-29") {
    ageToggleData.push("20-24", "25-29");
  } else if (ageGroup === "30-39") {
    ageToggleData.push("30-34", "35-39");
  } else if (ageGroup === "40-49") {
    ageToggleData.push("40-44", "45-49");
  } else if (ageGroup === "50-59") {
    ageToggleData.push("50-54", "55-59");
  } else if (ageGroup === "60-65+") {
    ageToggleData.push("60-64", "65+");
  }

  return (
    <div className="relative">
      <div
        className={
          activegroup === true
            ? "flex  mb-16 flex-col    transition-opacity duration-300 mx-0 px-0 py-8 opacity-100"
            : "flex  mb-16 flex-col  h-0 w-0 transition-opacity duration-0 mx-0 px-0 py-8 opacity-0 overflow-hidden"
        }
        activegroup={activegroup.toString()}
      >
        <div className="flex flex-col sm:flex-row gap-y-6 gap-x-4  mb-6">
          {" "}
          <h4 className="text-purple h-[40px]  bg-peach px-2 py-3 w-fit rounded-[5px] text-[16px] xs:text-[18px] leading-[16px] xs:leading-[18px] font-PhoenixSansMedium font-medium border border-peach">
            {index === 0 || index === 1
              ? "Expected Retirement Decade: 2060s"
              : index === 2 || index === 3
              ? "Expected Retirement Decade: 2050s"
              : index === 4 || index === 5
              ? "Expected Retirement Decade: 2040s"
              : index === 6 || index === 7
              ? "Expected Retirement Decade: 2030s"
              : "Expected Retirement Decade: 2020s"}
          </h4>
          <div className="flex gap-2">
            <button
              className={
                subAgeGroup === ageToggleData[0]
                  ? "h-[40px] rounded-full p-3 text-[18px] leading-[18px] font-bold font-PhoenixSansBold text-white bg-purple border border-purple"
                  : "h-[40px] rounded-full p-3 text-[18px] leading-[18px] font-medium font-PhoenixSansMedium text-purple bg-white hover:bg-purpleMuted border border-purple"
              }
              onClick={() => handleSubAgeGroup(ageToggleData[0])}
            >
              {ageToggleData[0]}
            </button>
            <button
              className={
                subAgeGroup === ageToggleData[1]
                  ? "h-[40px] rounded-full p-3 text-[18px] leading-[18px] font-bold font-PhoenixSansBold text-white bg-purple border border-purple"
                  : "h-[40px] rounded-full p-3 text-[18px] leading-[18px] font-medium font-PhoenixSansMedium text-purple bg-white hover:bg-purpleMuted border border-purple"
              }
              onClick={() => handleSubAgeGroup(ageToggleData[1])}
            >
              {ageToggleData[1]}
            </button>
          </div>
        </div>
        <h2 className="text-h3 sm:text-h2  mb-4 text-purple font-PhoenixSansMedium font-medium">
          {data?.groupName[index] === "20-24"
            ? "20 to 24 years old"
            : data?.groupName[index] === "25-29"
            ? "25 to 29 years old"
            : data?.groupName[index] === "30-34"
            ? "30 to 34 years old"
            : data?.groupName[index] === "35-39"
            ? "35 to 39 years old"
            : data?.groupName[index] === "40-44"
            ? "40 to 44 years old"
            : data?.groupName[index] === "45-49"
            ? "45 to 49 years old"
            : data?.groupName[index] === "50-54"
            ? "50 to 54 years old"
            : data?.groupName[index] === "55-59"
            ? "54 to 59 years old"
            : data?.groupName[index] === "60-64"
            ? "60 to 64 years old"
            : data?.groupName[index] === "65+"
            ? "65+ years old"
            : data?.groupName[index]}
        </h2>
        <div className="agegroup-intro " dangerouslySetInnerHTML={{ __html: data?.groupIntro[index] }} />
        <AgeGroupPanel
          data={data}
          ageGroupIndex={`group${index + 1}`}
          ageGroup={ageGroup}
          handleAgeGroup={handleAgeGroup}
          subAgeGroup={subAgeGroup}
          handleSubAgeGroup={handleSubAgeGroup}
          open={open}
          setOpen={setOpen}
          size={size}
          resizeFunction={resizeFunction}
        />
      </div>
    </div>
  );
};

export default GroupPanel;
