import axios from "axios";
import Papa from "papaparse";

export async function getData(sheetName) {
  // uses Google sheets api using trinitymirrorcreative@gmail.com credentials
  return new Promise((resolve, reject) => {
    const response = axios
      .get(
        "https://sheets.googleapis.com/v4/spreadsheets/1wGx5GgStSnSRyoieHErdt7f-8-8Ui6pc-oKFXbIen5A/values/" +
          sheetName +
          "?majorDimension=ROWS&key=AIzaSyA58fRS2M2mQPVH3jYgsZ6XJfWI8glf3sk"
      )
      .then((res) => {
        let csvContent = res.data.values.map((e) => e.join(";")).join("\n");
        const results = Papa.parse(csvContent, { delimiter: ";", newline: "", quoteChar: '"', header: true }); // object with { data, errors, meta }

        return results;
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
    resolve(response);
  });
}
