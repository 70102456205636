import { useHover } from "@uidotdev/usehooks";
import RadialBar from "../charts/RadialBar";
import CompareButtonPurple from "../../assets/img/compare-purple.svg";
import CompareButtonPink from "../../assets/img/compare-pink.svg";
import { calculateAverage } from "../../util/calculateAverage";

const ChartPanel = ({
  index,
  groupTitle,
  groupData,
  groupDescription,
  ageGroup,
  setSelectedMetric,
  setComparisonOn,
  comparisonOn,
  minMax,
  allGroupData,
  metricData,
  size,
  resizeFunction,
  avgs
}) => {
  const [ref, hovering] = useHover();

  const hasTrue = (el) => el === true;

  const changeToNumber = (el, index) => {
    if (index === 5) {
      return Number(groupData[index].replace(/,/g, "").slice(1));
    } else {
      return Number(groupData[index].replace("%", ""));
    }
  };

  // As per documentation the metrics are assigned a colour based on thier position, so order mertrics so they can be ranked and assigned colours
  let orderedMetrics = [];

  if (metricData) {
    orderedMetrics = metricData.sort(function (a, b) {
      return a - b;
    });
  }

  // Remove duplicate values
  const uniqueOrderedMetrics = [...new Set(orderedMetrics)];

  // remove first and last entries
  uniqueOrderedMetrics.shift();
  uniqueOrderedMetrics.pop();
  let mid = null;

  if (uniqueOrderedMetrics.length === 1) {
    mid = uniqueOrderedMetrics[0];
  } else if (uniqueOrderedMetrics.length === 3) {
    mid = uniqueOrderedMetrics[1];
    uniqueOrderedMetrics.splice(1, 1);
  } else if (uniqueOrderedMetrics === 5) {
    mid = uniqueOrderedMetrics[2];
    uniqueOrderedMetrics.spice(2, 1);
  }

  return (
    <div
      key={index}
      className={
        //  1000px +
        size.width >= 1000 && index < 3
          ? "col-span-2"
          : size.width >= 1000 && index >= 3 && index <= 12
          ? "col-span-3"
          : size.width >= 1000 && index > 12
          ? "col-span-2" 
          // : size.width >= 1000 && index > 12 && index < 15 
          // ? "col-span-3" 
          // : size.width >= 1000 && index == 15
          : // MD 768px - 1000px
          size.width >= 768 && size.width < 1000 && index < 4
          ? "col-span-3"
          : size.width >= 768 && size.width < 1000 && index >= 4 && index < 5
          ? "col-span-6"
          : size.width >= 768 && size.width < 1000 && index >= 3 && index <= 12
          ? "col-span-3"
          : size.width >= 768 && size.width < 1000 && index > 12 && index < 15
          ? "col-span-3"
          : size.width >= 768 && size.width < 1000 && index === 15
          ? "col-span-6"
          : //  SM 640px - 767px
          size.width >= 640 && size.width < 768 && (index === 4 || index === 15)
          ? "col-span-6"
          : size.width >= 640 && size.width < 768 && index !== 4 && index !== 15
          ? "col-span-3"
          : //  XS 475px - 639px
          size.width < 640
          ? "col-span-6"
          : "col-span-6"
      }
    >
      <div
        className={
          !comparisonOn && size.width >= 1000 && index > 12
            ? "relative flex flex-col w-full items-stretch justify-between bg-[#fef4eb] px-4 py-8 h-[224px] opacity-0 transition-all duration-500 ease-in-out"
            : comparisonOn && size.width >= 1000 && index > 12 
            ? "relative flex flex-col w-full items-stretch justify-between bg-[#fef4eb] px-4 py-8 h-[224px] opacity-100 transition-all duration-500 ease-in-out"
            : !comparisonOn && size.width < 1000 && index > 12  ? "relative flex flex-col w-full items-stretch justify-between bg-[#fef4eb] px-4 py-6 sm:py-8 h-[136px] sm:h-[168px] md:h-[200px] opacity-0 transition-all duration-500 ease-in-out"
            : comparisonOn && size.width < 1000 && index > 12  ? "relative flex flex-col w-full items-stretch justify-between bg-[#fef4eb] px-4 py-6 sm:py-8 h-[166px] xs:h-[136px] sm:h-[168px] md:h-[200px] opacity-100 transition-all duration-500 ease-in-out"
            : "relative flex flex-col w-full items-stretch justify-between bg-[#fef4eb] px-4 py-6 sm:py-8 h-[136px] sm:h-[168px] md:h-[200px] opacity-100 transition-all duration-500 ease-in-out"
        }
      >




        {!comparisonOn.some(hasTrue) && (
          <div ref={ref}>
            {hovering && (
              <>
                <img
                  src={CompareButtonPink}
                  alt="Compare"
                  className="absolute right-6 w-8 h-8 cursor-pointer"
                  onClick={() => {
                    setSelectedMetric(index);

                    const visibleComparison = comparisonOn;
                    visibleComparison?.forEach((el, i) => {
                      visibleComparison[i] = false;
                    });
                    visibleComparison[index] = true;

                    setComparisonOn(visibleComparison);
                    resizeFunction();
            setTimeout(() => {
              resizeFunction();
            }, 500);
                  }}
                />
                <div className="absolute z-50 top-20 right-[11px] p-2 text-[14px] leading-[14px] bg-purple text-white">Read more</div>
              </>
            )}
            {!hovering && (
              <>
                <img
                  src={CompareButtonPurple}
                  alt="Compare"
                  className="absolute right-6 w-8 h-8 cursor-pointer"
                  onClick={() => {
                    setSelectedMetric(index);

                    const visibleComparison = comparisonOn;
                    visibleComparison?.forEach((el, i) => {
                      visibleComparison[i] = false;
                    });
                    visibleComparison[index] = true;

                    setComparisonOn(visibleComparison);
                  }}
                />
              </>
            )}
          </div>
        )}

        <h4 className={size.width >= 1000 && index > 12 ? "text-h5 lg:text-h4  self-start h-32 mb-4 w-full font-semibold text-purple pr-16" : "text-h5 lg:text-h4  self-start h-20 w-full font-semibold text-purple pr-16"}>{groupTitle[index]}</h4>
        <div className="flex w-full items-center justify-between overflow-hidden py-2">
          <div className="flex w-fit items-center h-12 xs:h-12 overflow-hidden   pt-6">
            {index !== 5 && (
              <div className="block h-24 w-24 overflow-hidden  -ml-2 animate-appear invisible mt-4">
                <RadialBar
                  chartValue={groupData[index].replace("%", "")}
                  chartTitle={groupTitle[index]}
                  chartDescription={groupDescription[index]}
                  ageGroup={ageGroup}
                  minMax={minMax}
                  metricData={metricData}
                  uniqueOrderedMetrics={uniqueOrderedMetrics}
                  mid={mid}
                  changeToNumber={changeToNumber}
                />
              </div>
            )}

            <h4
              className={
                index === 5 &&
                minMax &&
                uniqueOrderedMetrics &&
                changeToNumber(groupData[index], index).toFixed(2).toString() === minMax[1].toFixed(2)
                  ? "p-1.5  text-h5 md:text-h4 font-semibold text-purple bg-[#63BE7B] mt-0 pb-4 "
                  : index === 5 &&
                    uniqueOrderedMetrics &&
                    uniqueOrderedMetrics.length === 4 &&
                    changeToNumber(groupData[index], index).toFixed(2).toString() === uniqueOrderedMetrics[3]
                  ? "p-1.5  text-h5 md:text-h4 font-semibold text-purple bg-[#79c888] mt-0 pb-4 "
                  : index === 5 &&
                    uniqueOrderedMetrics &&
                    uniqueOrderedMetrics.length === 2 &&
                    changeToNumber(groupData[index], index).toFixed(2).toString() === uniqueOrderedMetrics[1]
                  ? "p-1.5  text-h5 md:text-h4 font-semibold text-purple bg-[#afbe7b] mt-0 pb-4 "
                  : index === 5 &&
                    uniqueOrderedMetrics &&
                    uniqueOrderedMetrics.length === 4 &&
                    changeToNumber(groupData[index], index).toFixed(2).toString() === uniqueOrderedMetrics[2]
                  ? "p-1.5  text-h5 md:text-h4 font-semibold text-purple bg-[#bedc8d] mt-0 pb-4 "
                  : index === 5 && uniqueOrderedMetrics && changeToNumber(groupData[index], index).toFixed(2).toString() === mid
                  ? "p-1.5  text-h5 md:text-h4 font-semibold text-purple bg-[#EFE784] mt-0 pb-4 "
                  : index === 5 &&
                    uniqueOrderedMetrics &&
                    uniqueOrderedMetrics.length === 4 &&
                    changeToNumber(groupData[index], index).toFixed(2).toString() === uniqueOrderedMetrics[1]
                  ? "p-1.5  text-h5 md:text-h4 font-semibold text-purple bg-[#f6d37f] mt-0 pb-4 "
                  : index === 5 &&
                    uniqueOrderedMetrics &&
                    uniqueOrderedMetrics.length === 2 &&
                    changeToNumber(groupData[index], index).toFixed(2).toString() === uniqueOrderedMetrics[0]
                  ? "p-1.5  text-h5 md:text-h4 font-semibold text-purple bg-[#FCBF7B] mt-0 pb-4 "
                  : index === 5 &&
                    uniqueOrderedMetrics &&
                    uniqueOrderedMetrics.length === 4 &&
                    changeToNumber(groupData[index], index).toFixed(2).toString() === uniqueOrderedMetrics[0]
                  ? "p-1.5  text-h5 md:text-h4 font-semibold text-purple bg-[#fa9473] mt-0 pb-4 "
                  : index === 5 &&
                    minMax &&
                    uniqueOrderedMetrics &&
                    changeToNumber(groupData[index], index).toFixed(2).toString() === minMax[0].toFixed(2)
                  ? " text-h5 md:text-h4 font-semibold text-purple bg-[#F8696B] p-1.5 mt-0 pb-4"
                  : " text-h5 md:text-h4 font-semibold text-purple  mb-2"
              }
            >
              {groupData[index]}
            </h4>
          </div>

          <div className=" w-fit bg-peach text-purple mt-3 p-1 ml-2 font-medium font-PhoenixSansMedium text-[15px] rounded-[4px] whitespace-nowrap">
            {" "}
            {/* Average: {index === 5 ? `£${calculateAverage(metricData, index).toFixed(0)}` : `${calculateAverage(metricData, index)}%`} */}
            Average: {avgs[index]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartPanel;
