import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./routing/Routes";
import { getData } from "./util/getData";

const App = () => {
  const [noOfGroups, setNoOfGroups] = useState(10);
  const [ageGroup, setAgeGroup] = useState("40-49");
  const [subAgeGroup, setSubAgeGroup] = useState("40-44");
  const [data, setData] = useState(null);

  const handleAgeGroup = (age) => {
    setAgeGroup(age);
  };

  const handleSubAgeGroup = (age) => {
    setSubAgeGroup(age);
  };

  useEffect(() => {
    const updateData = async () => {
      // Get Intro sheet data and add to object
      const awaitedData = await getData("All Data").catch((error) => console.error(error));

      const intro = [];
      const groupIntro = [];
      const groupName = [];
      const groups = {};

      for (let i = 1; i < noOfGroups + 1; i++) {
        let group = `group${i}`;
        let groupData = `group${i}data`;
        let groupTitle = `group${i}title`;
        let groupCategory = `group${i}category`;
        let groupDescription = `group${i}description`;
        let groupFirstInCat = `group${i}firstincat`;

        groups[group] = {};

        if (groups.hasOwnProperty(group)) {
          groups[group][groupData] = [];
          groups[group][groupTitle] = [];
          groups[group][groupCategory] = [];
          groups[group][groupDescription] = [];
          groups[group][groupFirstInCat] = [];
        }
      }

      awaitedData.data.forEach((row) => {
        if (row.Intro) {
          intro.push(row.Intro);
        }

        if (row["Group Intro"]) {
          groupIntro.push(row["Group Intro"]);
        }

        if (row["Group Name"]) {
          groupName.push(row["Group Name"]);
        }

        for (let i = 1; i < noOfGroups + 1; i++) {
          let group = `group${i}`;
          let groupData = `group${i}data`;
          let groupTitle = `group${i}title`;
          let groupCategory = `group${i}category`;
          let groupDescription = `group${i}description`;
          let groupFirstInCat = `group${i}firstincat`;


          if (row[`Group${i} Data`]) {
            groups[group][groupData].push(row[`Group${i} Data`]);
          }
          if (row[`Group${i} Title`]) {
            groups[group][groupTitle].push(row[`Group${i} Title`]);
          }
          if (row[`Group${i} Category`]) {
            groups[group][groupCategory].push(row[`Group${i} Category`]);
          }
          if (row[`Group${i} Description`]) {
            groups[group][groupDescription].push(row[`Group${i} Description`]);
          }
          if (row[`Group${i} First in Cat`]) {
            groups[group][groupFirstInCat].push(row[`Group${i} First in Cat`]);
          }
        }
      });

      let tempData = {
        intro: intro,
        groupIntro: groupIntro,
        groupName: groupName,
        groups: groups,
      };

      setData(tempData);
    };

    updateData();
  }, [noOfGroups]);

  

  return (
    // Update basename to match build path on server
    // <Router basename="/infographics/phoenix/">
    <Router basename="/">
      <Switch>
        <Route
          render={() => (
            <Routes
              data={data}
              noOfGroups={noOfGroups}
              ageGroup={ageGroup}
              handleAgeGroup={handleAgeGroup}
              subAgeGroup={subAgeGroup}
              handleSubAgeGroup={handleSubAgeGroup}
            />
          )}
        />
      </Switch>
    </Router>
  );
};

export default App;
