import { useState, useEffect } from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const RadialBar = ({ chartValue, chartTitle, chartDescription, ageGroup /* see data tab */, minMax, metricData, uniqueOrderedMetrics, mid }) => {
  const [chartVal, setChartVal] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setChartVal(chartValue);
    }, 500);
  });

  const chartData = [
    {
      id: chartTitle,
      data: [
        {
          x: chartDescription,
          y: chartVal,
        },
      ],
    },
  ];
console.log(uniqueOrderedMetrics)
console.log(chartValue)
console.log(uniqueOrderedMetrics.length === 4 && chartValue === uniqueOrderedMetrics[3])
  return (
  <>
 
    <ResponsiveRadialBar
      data={chartData}
      valueFormat=">-.2f"
      maxValue={100}
      startAngle={-90}
      endAngle={90}
      padding={0.4}
      innerRadius={0.15}
      cornerRadius={0}
      margin={{ top: 10, right: 10, bottom: 0, left: 0 }}
      enableRadialGrid={false}
      enableCircularGrid={false}
      radialAxisStart={null}
      circularAxisOuter={null}
      colors={
        chartValue == minMax[1]
          ? ["#63BE7B"]
          : (uniqueOrderedMetrics.length === 4 && chartValue === uniqueOrderedMetrics[3]) ||
            (uniqueOrderedMetrics.length === 6 && chartValue === uniqueOrderedMetrics[5]) ||
            (uniqueOrderedMetrics.length === 5 && chartValue === uniqueOrderedMetrics[4])
          ? ["#79c888"]
          : (uniqueOrderedMetrics.length === 2 && chartValue === uniqueOrderedMetrics[1]) ||
            (uniqueOrderedMetrics.length === 6 && chartValue === uniqueOrderedMetrics[4]) 
          ? ["#afbe7b"]
          : (uniqueOrderedMetrics.length === 4 && chartValue === uniqueOrderedMetrics[2]) ||
            (uniqueOrderedMetrics.length === 6 && chartValue === uniqueOrderedMetrics[3]) ||
            (uniqueOrderedMetrics.length === 5 && chartValue === uniqueOrderedMetrics[3])
          ? ["#bedc8d"]
          : chartValue === mid ||
          (uniqueOrderedMetrics.length === 5 && chartValue === uniqueOrderedMetrics[2])
          ? ["#EFE784"]
          : (uniqueOrderedMetrics.length === 4 && chartValue === uniqueOrderedMetrics[1]) ||
            (uniqueOrderedMetrics.length === 6 && chartValue === uniqueOrderedMetrics[2])||
            (uniqueOrderedMetrics.length === 5 && chartValue === uniqueOrderedMetrics[1])
          ? ["#f6d37f"]
          : (uniqueOrderedMetrics.length === 2 && chartValue === uniqueOrderedMetrics[0]) ||
            (uniqueOrderedMetrics.length === 6 && chartValue === uniqueOrderedMetrics[1])
          ? ["#FCBF7B"]
          : (uniqueOrderedMetrics.length === 4 && chartValue === uniqueOrderedMetrics[0]) ||
            (uniqueOrderedMetrics.length === 6 && chartValue === uniqueOrderedMetrics[0]) ||
            (uniqueOrderedMetrics.length === 5 && chartValue === uniqueOrderedMetrics[0])
          ? ["#fa9473"]
          : ["#F8696B"]
      }
      colorBy="index"
      tracksColor="#D6D2D7"
      motionConfig="slow"
      transitionMode="startAngle"
      isInteractive={false}
      legends={[]}
    />
    {/* <div>{uniqueOrderedMetrics.length}</div>
    <div>{chartValue}</div>
    <div>{mid}</div> */}
     </>
  );
};

export default RadialBar;
