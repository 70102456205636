import RadialBar from "../charts/RadialBar";

const ComparisonPanel = ({ selectedMetric, allGroupData, index, ageGroup, comparisonOn, minMax, metricData }) => {
  const hasTrue = (el) => el === true;

  // Get all data for selectirc metric from all age groups
  const selectedMetricAllGroups = {};

  // const changeToNumber = (el, index) => {
  //   if (index === 5) {
  //     return Number(groupData[index].replace(/,/g, "").slice(1));
  //   } else {
  //     return Number(groupData[index].replace("%", ""));
  //   }
  // };

  for (let x = 1; x < 11; x++) {
    selectedMetricAllGroups[`group${x}`] = {
      category: null,
      data: null,
      title: null,
      descripton: null,
      age:
        x === 1
          ? "20-24"
          : x === 2
          ? "25-29"
          : x === 3
          ? "30-34"
          : x === 4
          ? "35-39"
          : x === 5
          ? "40-44"
          : x === 6
          ? "45-49"
          : x === 7
          ? "50-54"
          : x === 8
          ? "55-59"
          : x === 9
          ? "60-64"
          : x === 10
          ? "65+"
          : null,
    };
  }

  if (selectedMetric > -1) {
    for (let x = 1; x < 11; x++) {
      if (allGroupData && allGroupData[`group${x}`] && allGroupData[`group${x}`][`group${x}title`] && selectedMetricAllGroups[`group${x}`]) {
        selectedMetricAllGroups[`group${x}`].title = allGroupData[`group${x}`][`group${x}title`][selectedMetric];
        selectedMetricAllGroups[`group${x}`].data = allGroupData[`group${x}`][`group${x}data`][selectedMetric];
        selectedMetricAllGroups[`group${x}`].category = allGroupData[`group${x}`][`group${x}category`][selectedMetric];
        selectedMetricAllGroups[`group${x}`].description = allGroupData[`group${x}`][`group${x}description`][selectedMetric];
      }
    }
  }

  // As per documentation the metrics are assigned a colour based on thier position, so order mertrics so they can be ranked and assigned colours
  let orderedMetrics = [];

  if (metricData) {
    orderedMetrics = metricData.sort(function (a, b) {
      return a - b;
    });
  }

  // Remove duplicate values
  const uniqueOrderedMetrics = [...new Set(orderedMetrics)];

  // remove first and last entries
  uniqueOrderedMetrics.shift();
  uniqueOrderedMetrics.pop();
  let mid = null;

  if (uniqueOrderedMetrics.length === 1) {
    mid = uniqueOrderedMetrics[0];
  } else if (uniqueOrderedMetrics.length === 3) {
    mid = uniqueOrderedMetrics[1];
    uniqueOrderedMetrics.splice(1, 1);
  } else if (uniqueOrderedMetrics.length === 5) {
    mid = uniqueOrderedMetrics[2];
    uniqueOrderedMetrics.splice(2, 1);
  }

  return (
    <div key={index} className="">
      <div className="relative flex flex-col w-full items-start bg-[#fef4eb] px-4 py-6 sm:py-8 h-36">
        <h5 className="bg-peach text-purple p-0.5 pt-1  font-PhoenixSansMedium font-medium mb-0 text-h5 leading-[18px]">
          {selectedMetricAllGroups[index]?.age}
        </h5>
        <div
          className={
            selectedMetric === 3 ? "flex w-full items-center h-20 overflow-hidden  pt-6" : "flex w-full items-center h-16 overflow-hidden  pt-4"
          }
        >
          {selectedMetric !== 5 && (
            <div className="block h-24 w-24  -ml-2 animate-appear invisible mt-6">
              {comparisonOn.some(hasTrue) && (
                <RadialBar
                  chartValue={selectedMetricAllGroups[index]?.data.replace("%", "")}
                  chartTitle={selectedMetricAllGroups[index]?.title}
                  chartDescription={selectedMetricAllGroups[index]?.description}
                  ageGroup={ageGroup}
                  minMax={minMax}
                  metricData={metricData}
                  uniqueOrderedMetrics={uniqueOrderedMetrics}
                  mid={mid}
                />
              )}
            </div>
          )}

{console.log("mid " + Number(mid))}
{console.log(Number(selectedMetricAllGroups[index].data?.replace(/,/g, "").slice(1)))}


          <h4
            className={
              selectedMetric === 5 &&
              minMax &&
              uniqueOrderedMetrics &&
              Number(selectedMetricAllGroups[index].data.replace(/,/g, "").slice(1)).toFixed(2).toString() === minMax[1].toFixed(2)
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#63BE7B] p-1.5 sm:pb-6 mt-4"
                : selectedMetric === 5 &&
                  uniqueOrderedMetrics &&
                  uniqueOrderedMetrics.length === 4 &&
                  Number(selectedMetricAllGroups[index].data.replace(/,/g, "").slice(1)).toFixed(2).toString() === uniqueOrderedMetrics[3]
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#79c888] p-1.5 sm:pb-6 mt-4"
                : selectedMetric === 5 &&
                  uniqueOrderedMetrics &&
                  uniqueOrderedMetrics.length === 2 &&
                  Number(selectedMetricAllGroups[index].data.replace(/,/g, "").slice(1)).toFixed(2).toString() === uniqueOrderedMetrics[1]
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#afbe7b] p-1.5 sm:pb-6 mt-4"
                : selectedMetric === 5 &&
                  uniqueOrderedMetrics &&
                  uniqueOrderedMetrics.length === 4 &&
                  Number(selectedMetricAllGroups[index].data.replace(/,/g, "").slice(1)).toFixed(2).toString() === uniqueOrderedMetrics[2]
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#bedc8d] p-1.5 sm:pb-6 mt-4"
                : selectedMetric === 5 && uniqueOrderedMetrics && Number(selectedMetricAllGroups[index].data?.replace(/,/g, "").slice(1)) == Number(mid).toFixed(0)
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#EFE784] p-1.5 sm:pb-6 mt-4"
                : selectedMetric === 5 &&
                  uniqueOrderedMetrics &&
                  uniqueOrderedMetrics.length === 4 &&
                  Number(selectedMetricAllGroups[index].data.replace(/,/g, "").slice(1)).toFixed(2).toString() === uniqueOrderedMetrics[1]
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#f6d37f] p-1.5 sm:pb-6 mt-4"
                : selectedMetric === 5 &&
                  uniqueOrderedMetrics &&
                  uniqueOrderedMetrics.length === 2 &&
                  Number(selectedMetricAllGroups[index].data.replace(/,/g, "").slice(1)).toFixed(2).toString() === uniqueOrderedMetrics[0]
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#FCBF7B] p-1.5 sm:pb-6 mt-4"
                : selectedMetric === 5 &&
                  uniqueOrderedMetrics &&
                  uniqueOrderedMetrics.length === 4 &&
                  Number(selectedMetricAllGroups[index].data.replace(/,/g, "").slice(1)).toFixed(2).toString() === uniqueOrderedMetrics[0]
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#fa9473] p-1.5 sm:pb-6 mt-4"
                : selectedMetric === 5 &&
                  minMax &&
                  uniqueOrderedMetrics &&
                  Number(selectedMetricAllGroups[index].data.replace(/,/g, "").slice(1)).toFixed(2).toString() === minMax[0].toFixed(2)
                ? " text-h5 sm:text-h4 font-semibold text-purple bg-[#F8696B] p-1.5 sm:pb-6 mt-4"
                : " text-h5 sm:text-h4 font-semibold text-purple mt-2 sm:mt-0"
            }
          >
            {selectedMetricAllGroups[index]?.data}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ComparisonPanel;
