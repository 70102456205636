import age16to29 from "../assets/img/16-29.svg";
import age30to39 from "../assets/img/30-39.svg";
import age40to49 from "../assets/img/40-49.svg";
import age50to59 from "../assets/img/50-59.svg";
import age60to65plus from "../assets/img/60-65plus.svg";

const AgeMenu = ({ ageGroup, handleAgeGroup, subAgeGroup, handleSubAgeGroup }) => {
  const ageGroups = ["20-29", "30-39", "40-49", "50-59", "60-65+"];

  return (
    <div className="bg-offWhite">
      <div className=" mx-auto pb-8  grid grid-cols-6 lg:grid-cols-10 gap-4 pt-8 mb-2 px-8 sm:px-16 max-w-[1128px] w-full">
        {ageGroups.map((group, index) => {
          return (
            <div
              className={
                index > 2
                  ? "flex flex-col justify-center items-center  col-span-3 lg:col-span-2"
                  : "flex flex-col justify-center items-center  col-span-2"
              }
              key={index}
            >
              <button
                key={index}
                className={
                  group === ageGroup
                    ? "group text-[18px] min-w-[84px] xs:min-w-[122px] sm:min-w-[161px] font-PhoenixSansBold font-bold rounded-md bg-white transion-all duration-150 w-full overflow-hidden"
                    : "group text-[18px] min-w-[84px] xs:min-w-[122px] sm:min-w-[161px] font-PhoenixSansBold font-bold rounded-md  bg-white text-white transion-all duration-150 w-full overflow-hidden"
                }
                onClick={() => {
                  handleAgeGroup(group);
                  // Set 1st subgroup as default for each main age group if selected.
                  if (group == "20-29") {
                    handleSubAgeGroup("20-24");
                  } else if (group == "30-39") {
                    handleSubAgeGroup("30-34");
                  } else if (group == "40-49") {
                    handleSubAgeGroup("40-44");
                  } else if (group == "50-59") {
                    handleSubAgeGroup("50-54");
                  } else if (group == "60-65+") {
                    handleSubAgeGroup("60-64");
                  }
                }}
              >
                <div
                  onClick={() => {
                    handleAgeGroup(group);
                  }}
                  className={
                    group === ageGroup
                      ? "relative bg-white flex flex-col justify-center items-center w-full    mx-auto  transition-all duration-1000 cursor-pointer overflow-hidden"
                      : "relative bg-white flex flex-col justify-center items-center w-full    mx-auto  transition-all duration-300 cursor-pointer overflow-hidden"
                  }
                >
                </div>
                <div className={group === ageGroup ? "py-5 px-2 text-purple bg-pink-900 border border-pink rounded-[5px]" : "py-5 px-2 text-purple bg-white border border-white font-medium font-PhoenixSansMedium hover:border-buttonBorderGrey rounded-[5px]"}>{group} years old</div>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AgeMenu;
