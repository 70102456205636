import { Fragment } from "react";

const Intro = ({ data }) => (
    <div className="bg-[url('assets/img/phoenix-aubergine-texture.jpg')] bg-center bg-cover text-white">
      <div className="container mx-auto py-16 px-6 sm:px-16 max-w-[1128px] ">
        {data.intro.map((par, index) => (
          <Fragment key={index}>
            {index === 0 && <h1 className="text-h4 xs:text-h2 sm:text-h1">{par}</h1>}
            {index !== 0 && <div className="intro" key={index} dangerouslySetInnerHTML={{ __html: par }} />}
          </Fragment>
        ))}
      </div>
    </div>
  );

export default Intro;
