import { useState } from "react";
import CloseButtonPink from "../../assets/img/close-pink.svg";
import CloseButtonPurple from "../../assets/img/close-purple.svg";

const CloseButton = ({ data, comparisonOn, setComparisonOn, hasTrue, setSelectedMetric, resizeFunction }) => {
  const [hovering, setHovering] = useState(false);

  return (
    <>
      {data && comparisonOn && comparisonOn.some(hasTrue) && (
        <div className="relative w-[40px] h-[40px] aspect-square   z-50 justify-self-end">
          {hovering && (
            <>
              <img
                src={CloseButtonPink}
                alt="Close"
                className="   w-[40px] h-[40px] cursor-pointer "
                onClick={() => {
                  setSelectedMetric(null);
                  setComparisonOn([]);
                  setHovering(false);
                  resizeFunction();
                  setTimeout(() => {
                    resizeFunction();
                  }, 500);
                }}
                onMouseOver={() => setHovering(true)}
                onMouseOut={() => setHovering(false)}
              />
              <div className="absolute  -bottom-10 -right-[6px] p-2 text-[14px] leading-[14px] bg-purple text-white z-50">Return</div>
            </>
          )}
          {!hovering && (
            <img
              src={CloseButtonPurple}
              alt="Close"
              className="   w-[40px] h-[40px] cursor-pointer"
              onClick={() => {
                setSelectedMetric(null);
                setComparisonOn([]);
                resizeFunction();
                setTimeout(() => {
                  resizeFunction();
                }, 500);
              }}
              onMouseOver={() => setHovering(true)}
              onMouseOut={() => setHovering(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CloseButton;
