import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Spinner = () => (
  <Box sx={{ display: "flex" }}>
    <CircularProgress
      sx={{
        color: "#331F37",
      }}
      size={50}
    />
  </Box>
);

export default Spinner;
