import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useState, useEffect } from "react";
import logo from "../assets/img/phoenix-white-logo.svg";
import Intro from "../components/layout/Intro";
import AgeMenu from "./AgeMenu";
import Spinner from "./Spinner";
import GroupPanel from "./layout/GroupPanel";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={`${id === open ? "rotate-0" : "rotate-180"}  ml-2 h-8 w-8 transition-transform ease-in-out duration-500`}
    >
      <path d="M7 20.5L16 11.5L25 20.5" stroke="#FFF4EC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

const CUSTOM_ANIMATION = {
  mount: { opacity: 1, transition: "opacity 500ms ease-in-out" },
  unmount: { opacity: 0, transition: "opacity 500ms ease-in-out" },
};

const PageTemplate = ({ data, noOfGroups, site, ageGroup, handleAgeGroup, subAgeGroup, handleSubAgeGroup }) => {
  const groups = [];
  const [open, setOpen] = useState(1);
  const [footerOpen, setFooterOpen] = useState(null);

  const size = useWindowSize();

  function resizeFunction() {
    var message = { height: document.getElementById("pageID").clientHeight };
    window.parent.postMessage(message, "*");
  }

  // when window changes height send message to iframe so iframe changes height too
  useEffect(() => {
    var message = { height: document.getElementById("pageID").clientHeight };
    window.parent.postMessage(message, "*");

    resizeFunction()

  })

  const handleFooterOpen = (value) => {
    setFooterOpen(footerOpen === value ? 0 : value);
  };

  for (let i = 0; i < noOfGroups; i++) {
    const activegroup = subAgeGroup === data?.groupName[i];

    groups.push(
      <GroupPanel
        key={i}
        data={data}
        index={i}
        open={open}
        setOpen={setOpen}
        activegroup={activegroup}
        ageGroup={ageGroup}
        handleAgeGroup={handleAgeGroup}
        subAgeGroup={subAgeGroup}
        handleSubAgeGroup={handleSubAgeGroup}
        size={size}
        resizeFunction={resizeFunction}
      />
    );
  }






  return (
    <div id="pageID">
      <div className="w-full bg-purple h-[100px] flex items-center">
        <div className="container mx-auto px-6 sm:px-16 max-w-[1128px]">
          {site === "reach" && (
            <a href="https://www.thephoenixgroup.com/" target="_blank" rel="noreferrer">
              <img src={logo} alt="Phoenix Group" className="w-[150px] xs:w-[200px]" />
            </a>
          )}
          {site === "phoenix" && <img src={logo} alt="Phoenix Group" className="w-[200px]  " />}
        </div>
      </div>
      {data && <Intro data={data} />}
      {data && (
        <AgeMenu ageGroup={ageGroup} handleAgeGroup={handleAgeGroup} subAgeGroup={subAgeGroup} handleSubAgeGroup={handleSubAgeGroup} size={size} />
      )}
      <div className="container mx-auto px-6 sm:px-16 pb-16  max-w-[1128px]">
        {groups && <div className="flex  justify-center   w-full mx-auto mb-16">{groups}</div>}
        {!data && (
          <div className="flex justify-center items-center w-full h-[calc(100vh-521px)] ">
            <div className="-translate-y-1/2">
              <Spinner />
            </div>
          </div>
        )}
      </div>
      <div className="w-full bg-purple  flex items-center">
        <div className="container mx-auto px-6 sm:px-16 py-6 max-w-[1128px]">
          {site === "reach" && (
            <a href="https://www.thephoenixgroup.com/" target="_blank" rel="noreferrer">
              <img src={logo} alt="Phoenix Group" className="w-[150px] py-4 " />
            </a>
          )}
          <Accordion
            className="footer-accordion-header transition-all ease-in-out duration-500 mb-4"
            open={footerOpen === 1}
            icon={<Icon id={1} open={footerOpen} />}
            animate={CUSTOM_ANIMATION}
          >
            <AccordionHeader
              className=" flex justify-start border-b-0 transition-all ease-in-out duration-500 -mb-2  text-offWhite"
              onClick={() => {
                handleFooterOpen(1);
                resizeFunction();
                setTimeout(() => {
                resizeFunction();
              }, 500);
              }}
            >
              <h4 className="text-h4">Sources</h4>
            </AccordionHeader>

            <AccordionBody className={` relative transition-all ease-in-out duration-500 `}>
              <div className={"transition-all duration-500 ease-in-out opacity-100 text-offWhite pl-4"}>
                <ul className="list-disc list-outside text-offWhite text-pRegular leading-[22px] pl-2">
                <li className="text-offWhite">ONS (2023) Analysis of future pension incomes</li>
                <li className="text-offWhite">NatCen (2023) Planning for retirement: the pensions gap and attitudes to working longer</li>
                <li className="text-offWhite">ONS (2023) Earnings and hours worked, age group ASHE Table 6</li>
                <li className="text-offWhite">Understanding Society (2023) The UK Household Longitudinal Study</li>
                <li className="text-offWhite">ONS (2023) Low and high pay in the UK: 2023</li>
                <li className="text-offWhite">ONS (2022) Job quality in the UK – analysis of job quality indicators: 2021</li>
                <li className="text-offWhite">CIPD (2022) Understanding older workers</li>
                <li className="text-offWhite">ONS (2019) Which occupations are at highest risk of being automated?</li>

                  {/* <li className=" ">
                    <a
                      className="text-offWhite underline break-words"
                      href="https://www.gov.uk/government/statistics/analysis-of-future-pension-incomes/analysis-of-future-pension-incomes#depth-of-undersaving"
                      target="_blank"
                    >
                      https://www.gov.uk/government/statistics/analysis-of-future-pension-incomes/analysis-of-future-pension-incomes#depth-of-undersaving
                    </a>
                  </li>
                  <li className="text-offWhite">NatCen analysis of Behavioural Social Attitudes Survey for Phoenix Insights</li>
                  <li>
                    <a
                      className="text-offWhite underline break-words"
                      href="https://www.ons.gov.uk/peoplepopulationandcommunity/personalandhouseholdfinances/incomeandwealth/adhocs/14717averagedisposableincomebyagebandafterhousingcostsuk2020"
                      target="_blank"
                    >
                      https://www.ons.gov.uk/peoplepopulationandcommunity/personalandhouseholdfinances/incomeandwealth/adhocs/14717averagedisposableincomebyagebandafterhousingcostsuk2020
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-offWhite underline break-words"
                      href="https://www.understandingsociety.ac.uk/documentation/mainstage"
                      target="_blank"
                    >
                      https://www.understandingsociety.ac.uk/documentation/mainstage
                    </a>
                  </li>
                  <li className="text-offWhite">Annual Survey of Hours and Earnings, Office for National Statistics</li>
                  <li>
                    <a
                      className="text-offWhite underline break-words"
                      href="https://www.cipd.org/globalassets/media/knowledge/knowledge-hub/reports/understanding-older-workers-report_tcm18-107672.pdfg"
                      target="_blank"
                    >
                      https://www.cipd.org/globalassets/media/knowledge/knowledge-hub/reports/understanding-older-workers-report_tcm18-107672.pdfg
                    </a>
                  </li> */}
                </ul>
              </div>
            </AccordionBody>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;
