import React from "react";
import { Route, Switch } from "react-router-dom";
import PageTemplate from "../components/PageTemplate";

const Routes = ({ data, noOfGroups, ageGroup, handleAgeGroup, subAgeGroup, handleSubAgeGroup }) => (
  <Switch>
    <Route
      exact
      path="/"
      render={() => (
        <PageTemplate
          data={data}
          noOfGroups={noOfGroups}
          site="reach"
          ageGroup={ageGroup}
          handleAgeGroup={handleAgeGroup}
          subAgeGroup={subAgeGroup}
          handleSubAgeGroup={handleSubAgeGroup}
        />
      )}
    />
    
    <Route
      exact
      path="/phoenix"
      render={() => (
        <PageTemplate
          data={data}
          noOfGroups={noOfGroups}
          site="phoenix"
          ageGroup={ageGroup}
          handleAgeGroup={handleAgeGroup}
          subAgeGroup={subAgeGroup}
          handleSubAgeGroup={handleSubAgeGroup}
        />
      )}
    />
  </Switch>
);

export default Routes;
