import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import tw from "tailwind-styled-components";
import { calculateAverage } from "../../util/calculateAverage";
import ChartPanel from "./ChartPanel";
import CloseButton from "./CloseButton";
import ComparisonPanel from "./ComparisonPanel";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={`${id === open ? "rotate-0" : "rotate-180"}   h-8 w-8 transition-transform ease-in-out duration-500`}
    >
      <path d="M7 20.5L16 11.5L25 20.5" stroke="#331F37" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

const CUSTOM_ANIMATION = {
  mount: { opacity: 1, transition: "opacity 500ms ease-in-out" },
  unmount: { opacity: 0, transition: "opacity 500ms ease-in-out" },
};

const AgeGroupPanel = ({ data, ageGroupIndex, open, setOpen, size, resizeFunction }) => {
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [comparisonOn, setComparisonOn] = useState([]);
  const [accordionHeights, setAccordionHeights] = useState(null);

  const ref1a = useRef(null);
  const ref1b = useRef(null);
  const ref2a = useRef(null);
  const ref2b = useRef(null);
  const ref3a = useRef(null);
  const ref3b = useRef(null);
  const ref4a = useRef(null);
  const ref4b = useRef(null);

  useEffect(() => {
    if (selectedMetric !== null) {
      if (ref1b.current && open === 1) {
        setAccordionHeights(ref1b?.current.clientHeight);
      } else if (ref2b.current && open === 2) {
        setAccordionHeights(ref2b?.current.clientHeight);
      } else if (ref3b.current && open === 3) {
        setAccordionHeights(ref3b?.current.clientHeight);
      } else if (ref4b.current && open === 4) {
        setAccordionHeights(ref4b?.current.clientHeight);
      }
    } else {
      if (ref1a.current && open === 1) {
        setAccordionHeights(ref1a?.current.clientHeight);
      } else if (ref2a.current && open === 2) {
        setAccordionHeights(ref2a?.current.clientHeight);
      } else if (ref3a.current && open === 3) {
        setAccordionHeights(ref3a?.current.clientHeight);
      } else if (ref4a.current && open === 4) {
        setAccordionHeights(ref4a?.current.clientHeight);
      }
    }
  });

  const DataCats = tw.div` pt-4 grid grid-cols-1 sm:grid-cols-6 gap-4 auto-rows-min overflow-visible`;
  const DataCatsCompare = tw.div` pt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4 auto-rows-min overflow-visible`;
  const allGroupData = data?.groups;
  const groupData = data?.groups?.[ageGroupIndex][`${ageGroupIndex}data`];
  const groupTitle = data?.groups?.[ageGroupIndex][`${ageGroupIndex}title`];
  const groupCategory = data?.groups?.[ageGroupIndex][`${ageGroupIndex}category`];
  const groupDescription = data?.groups?.[ageGroupIndex][`${ageGroupIndex}description`];
  const groupFirstInCat = data?.groups?.[ageGroupIndex][`${ageGroupIndex}firstincat`];
  const metrics = [];
  const minmax = [];

  let groupDataNum = [];

  if (groupData) {
    for (let x = 0; x < groupData.length; x++) {
      if (groupData[x][0] !== "£") {
        groupDataNum.push(parseInt(groupData[x]));
      }
    }
  }

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
    setTimeout(() => {
      setSelectedMetric(null);
      setComparisonOn([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
    }, 300);
  };

  const minMax = (arr) => arr.reduce(([min, max = min], num) => [Math.min(num, min), Math.max(num, max)], arr);

  const minMaxSubGroup = (start, end, data) => {
    const slicedData = data.slice(start, end);
    const [min, max] = minMax(slicedData);
    return [min, max];
  };

  const hasTrue = (el) => el === true;

  if (allGroupData) {
    for (let i = 0; i < 16; i++) {
      metrics.push([]);
      for (let x = 1; x < 11; x++) {
        if (i === 5) {
          metrics[i].push(parseFloat(allGroupData[`group${x}`][`group${x}data`][i].replace(/,/g, "").slice(1)).toFixed(2));
        } else {
          metrics[i].push(allGroupData[`group${x}`][`group${x}data`][i].replace("%", ""));
        }
      }
      minmax.push(minMaxSubGroup(0, metrics[i].length, metrics[i]));
    }
  }

  const avgs = ["88%", "49%", "12%", "61%", "42%", "£29,669", "93%", "49%", "89%", "97%", "84%", "91%", "88%", "82%", "55%", "92%"];

  return (
    <div className="relative">
      {/* Projected pension saving */}

      <Accordion
        className="accordion-header transition-all ease-in-out duration-500"
        open={open === 1}
        icon={<Icon id={1} open={open} />}
        animate={CUSTOM_ANIMATION}
      >
        <AccordionHeader
          className="flex justify-start border-b-0 transition-all ease-in-out duration-500 -mb-3"
          onClick={() => {
            handleOpen(1);
            resizeFunction();
            setTimeout(() => {
              resizeFunction();
            }, 500);
          }}
        >
          {data && groupFirstInCat[0] === "TRUE" && (
            <h3 className="text-h4 sm:text-h3 text-purple font-PhoenixSansMedium font-medium mt-2 mb-2 mr-2 text-left">{groupCategory[0]}</h3>
          )}
          {data && groupFirstInCat[0] !== "TRUE" && <div></div>}
        </AccordionHeader>

        <AccordionBody
          style={{ height: selectedMetric === null ? accordionHeights + 40 : accordionHeights + 120 }}
          className={` relative transition-all ease-in-out duration-500 `}
        >
          <div
            ref={ref1a}
            className={
              selectedMetric === null ? "transition-all duration-500 ease-in-out opacity-100" : "transition-all duration-500 ease-in-out opacity-0"
            }
          >
            {!comparisonOn.some(hasTrue) && (
              <p className="text-darkGrey text-pLarge pb-6">
                Building up pension savings over your working life is one key way in which people across the UK are getting ready for retirement. The
                amount that people are able to put aside now will determine the lifestyle that they can afford in the future. Pensions experts have
                come up with metrics to assess how much people will need to save in order to afford different standards of living in retirement –
                ranging from ‘minimum’, ‘moderate’ to ‘comfortable’. These metrics capture how on track each age group is to meet these standards
                based on their projected pension savings.
              </p>
            )}
            <DataCats>
              {data &&
                !comparisonOn.some(hasTrue) &&
                groupData.map((par, index) => {
                  if (index < 5) {
                    return (
                      <ChartPanel
                        key={index}
                        index={index}
                        groupTitle={groupTitle}
                        groupData={groupData}
                        groupDescription={groupDescription}
                        ageGroupIndex={ageGroupIndex}
                        setSelectedMetric={setSelectedMetric}
                        setComparisonOn={setComparisonOn}
                        comparisonOn={comparisonOn}
                        minMax={minmax[index]}
                        allGroupData={allGroupData}
                        metricData={metrics[index]}
                        size={size}
                        resizeFunction={resizeFunction}
                        avgs={avgs}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </DataCats>
          </div>
          {data && (
            <>
              <div
                className={
                  selectedMetric || selectedMetric !== null
                    ? "flex transition-all duration-500 ease-in-out opacity-100"
                    : "flex transition-all duration-500 ease-in-out opacity-0"
                }
              >
                <div className="flex justify-between items-start w-full  pb-4 mr-1.5 gap-8 xs:gap-8 ">
                  <div className="w-full flex flex-col xs:flex-row items-start xs:items-center gap-4 xs:gap-8">
                    {data && (
                      <h4 className="w-fit text-h5 sm:text-h4 text-purple font-medium font-PhoenixSansMedium">{groupTitle[selectedMetric]}</h4>
                    )}
                    {metrics[selectedMetric] && (
                      <div className="h-fit bg-peach text-purple  p-1 font-medium font-PhoenixSansMedium text-[15px] leading-[15px] rounded-[4px] whitespace-nowrap">
                        Average:{" "}
                        {/* {selectedMetric === 5 ? `£${calculateAverage(metrics[selectedMetric])}` : `${calculateAverage(metrics[selectedMetric])}%`} */}
                        {avgs[selectedMetric]}
                      </div>
                    )}
                  </div>
                  <CloseButton
                    data={data}
                    comparisonOn={comparisonOn}
                    setComparisonOn={setComparisonOn}
                    hasTrue={hasTrue}
                    setSelectedMetric={setSelectedMetric}
                    resizeFunction={resizeFunction}
                  />
                </div>
              </div>
              <div
                ref={ref1b}
                className={
                  selectedMetric || selectedMetric !== null
                    ? "-mt-1 absolute h-fit w-full transition-all duration-500 ease-in-out opacity-100"
                    : "-mt-1 absolute h-fit w-full transition-all duration-500 ease-in-out opacity-0"
                }
              >
                <p className="text-darkGrey text-pLarge my-6">{groupDescription[selectedMetric]}</p>
                <DataCatsCompare>
                  {(selectedMetric !== null || typeof selectedMetric !== "undefined") &&
                    Object.keys(data?.groups).map((index, key) => (
                      <div key={index}>
                        <ComparisonPanel
                          selectedMetric={selectedMetric}
                          allGroupData={allGroupData}
                          index={index}
                          groupTitle={groupTitle}
                          groupData={groupData}
                          groupDescription={groupDescription}
                          ageGroupIndex={ageGroupIndex}
                          setSelectedMetric={setSelectedMetric}
                          setComparisonOn={setComparisonOn}
                          comparisonOn={comparisonOn}
                          minMax={minmax[selectedMetric]}
                          metricData={metrics[selectedMetric]}
                        />
                      </div>
                    ))}
                </DataCatsCompare>
              </div>
            </>
          )}
        </AccordionBody>
      </Accordion>

      {/* Current Capacity to save  */}

      <Accordion
        className="accordion-header transition-all ease-in-out duration-500 mt-4"
        open={open === 2}
        icon={<Icon id={2} open={open} />}
        animate={CUSTOM_ANIMATION}
      >
        <AccordionHeader
          className="flex justify-start border-b-0 transition-all ease-in-out duration-500 -mb-3"
          onClick={() => {
            handleOpen(2);
            resizeFunction();
            setTimeout(() => {
              resizeFunction();
            }, 500);
          }}
        >
          {data && groupFirstInCat[5] === "TRUE" && (
            <h3 className="text-h4 sm:text-h3 text-purple font-PhoenixSansMedium font-medium  mb-2 mr-2">{groupCategory[5]}</h3>
          )}
          {data && groupFirstInCat[5] !== "TRUE" && <div></div>}
        </AccordionHeader>

        <AccordionBody
          style={{ height: selectedMetric === null ? accordionHeights + 40 : accordionHeights + 180 }}
          className="relative transition-all ease-in-out duration-500"
        >
          <div
            ref={ref2a}
            className={
              !selectedMetric || selectedMetric === null
                ? "transition-all duration-500 ease-in-out opacity-100"
                : "transition-all duration-500 ease-in-out opacity-0"
            }
          >
            {!comparisonOn.some(hasTrue) && (
              <p className="text-darkGrey text-pLarge pb-6">
                Preparing for retirement typically happens over a long period of time – as people start to think about what they want that stage in
                their life to look like, and begin to put money aside. However, people’s financial futures often depend on their finances today. These
                metrics build a picture of people’s capacity to save based on level of income, whether they are struggling financially, and how much
                money is being put aside on their behalf by their employer. These factors contribute to how able someone is to regularly save for
                retirement and the future they want.
              </p>
            )}
            <DataCats>
              {data &&
                !comparisonOn.some(hasTrue) &&
                groupData.map((par, index) => {
                  if (index >= 5 && index < 9) {
                    return (
                      <ChartPanel
                        key={index}
                        index={index}
                        groupTitle={groupTitle}
                        groupData={groupData}
                        groupDescription={groupDescription}
                        ageGroupIndex={ageGroupIndex}
                        setSelectedMetric={setSelectedMetric}
                        setComparisonOn={setComparisonOn}
                        comparisonOn={comparisonOn}
                        minMax={minmax[index]}
                        allGroupData={allGroupData}
                        metricData={metrics[index]}
                        size={size}
                        resizeFunction={resizeFunction}
                        avgs={avgs}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </DataCats>
          </div>
          {data && (
            <>
              <div
                className={
                  selectedMetric || selectedMetric !== null
                    ? "flex transition-all duration-500 ease-in-out opacity-100"
                    : "flex transition-all duration-500 ease-in-out opacity-0"
                }
              >
                <div className="flex justify-between items-start w-full  pb-4 mr-1.5 gap-8 xs:gap-8 ">
                  <div className="w-full flex flex-col xs:flex-row items-start xs:items-center gap-4 xs:gap-8">
                    {data && (
                      <h4 className="w-fit text-h5 sm:text-h4 text-purple font-medium font-PhoenixSansMedium">{groupTitle[selectedMetric]}</h4>
                    )}
                    {metrics[selectedMetric] && (
                      <div className="h-fit bg-peach  p-1 font-medium font-PhoenixSansMedium text-[15px] leading-[15px] rounded-[4px] whitespace-nowrap">
                        Average:{" "}
                        {/* {selectedMetric === 5 ? `£${calculateAverage(metrics[selectedMetric])}` : `${calculateAverage(metrics[selectedMetric])}%`} */}
                        {avgs[selectedMetric]}
                      </div>
                    )}
                  </div>
                  <CloseButton
                    data={data}
                    comparisonOn={comparisonOn}
                    setComparisonOn={setComparisonOn}
                    hasTrue={hasTrue}
                    setSelectedMetric={setSelectedMetric}
                    resizeFunction={resizeFunction}
                  />
                </div>
              </div>
              <div
                ref={ref2b}
                className={
                  selectedMetric || selectedMetric !== null
                    ? "mt-3 absolute h-fit w-full transition-all duration-500 ease-in-out opacity-100"
                    : "mt-3 absolute h-fit w-full transition-all duration-500 ease-in-out opacity-0"
                }
              >
                <p className="text-darkGrey text-pLarge my-6">{groupDescription[selectedMetric]}</p>

                <DataCatsCompare>
                  {selectedMetric &&
                    comparisonOn.some(hasTrue) &&
                    Object.keys(data?.groups).map((index, key) => (
                      <div key={index} className={comparisonOn ? "block" : "hidden"}>
                        <ComparisonPanel
                          selectedMetric={selectedMetric}
                          allGroupData={allGroupData}
                          index={index}
                          groupTitle={groupTitle}
                          groupData={groupData}
                          groupDescription={groupDescription}
                          ageGroupIndex={ageGroupIndex}
                          setSelectedMetric={setSelectedMetric}
                          setComparisonOn={setComparisonOn}
                          comparisonOn={comparisonOn}
                          minMax={minmax[selectedMetric]}
                          metricData={metrics[selectedMetric]}
                        />
                      </div>
                    ))}
                </DataCatsCompare>
              </div>
            </>
          )}
        </AccordionBody>
      </Accordion>

      {/* Current job quality */}

      <Accordion
        className="accordion-header transition-all  ease-in-out duration-500  mt-4"
        open={open === 3}
        icon={<Icon id={3} open={open} />}
        animate={CUSTOM_ANIMATION}
      >
        <AccordionHeader
          className="flex justify-start border-b-0 transition-all ease-in-out duration-500 -mb-3 "
          onClick={() => {
            handleOpen(3);
            resizeFunction();
            setTimeout(() => {
              resizeFunction();
            }, 500);
          }}
        >
          {data && groupFirstInCat[9] === "TRUE" && (
            <h3 className="text-h4 sm:text-h3 text-purple font-PhoenixSansMedium font-medium mt-2 mb-2 mr-2">{groupCategory[9]}</h3>
          )}
          {data && groupFirstInCat[9] !== "TRUE" && <div></div>}
        </AccordionHeader>

        <AccordionBody
          style={{ height: selectedMetric === null ? accordionHeights + 40 : accordionHeights + 180 }}
          className="relative transition-all ease-in-out duration-500"
        >
          <div
            ref={ref3a}
            className={
              !selectedMetric || selectedMetric === null
                ? "transition-all duration-500 ease-in-out opacity-100"
                : "transition-all duration-500 ease-in-out opacity-0"
            }
          >
            {!comparisonOn.some(hasTrue) && (
              <p className="text-darkGrey text-pLarge pb-6">
                People’s ability to save today is often impacted by the work that they do - whether they are able to earn regular and sufficient
                income. This isn’t about the type of work or sector someone is in, but can be impacted by contract and pay conditions. It can also be
                impacted by external factors, such as caring responsibilities, or limitations to the amount of work that someone can do due to long-term
                health issues. These metrics capture a range of work-related factors that may contribute to people’s ability to get retirement ready.
              </p>
            )}
            <DataCats>
              {data &&
                !comparisonOn.some(hasTrue) &&
                groupData.map((par, index) => {
                  if (index >= 9 && index < 13) {
                    return (
                      <ChartPanel
                        key={index}
                        index={index}
                        groupTitle={groupTitle}
                        groupData={groupData}
                        groupDescription={groupDescription}
                        ageGroupIndex={ageGroupIndex}
                        setSelectedMetric={setSelectedMetric}
                        setComparisonOn={setComparisonOn}
                        comparisonOn={comparisonOn}
                        minMax={minmax[index]}
                        allGroupData={allGroupData}
                        metricData={metrics[index]}
                        size={size}
                        resizeFunction={resizeFunction}
                        avgs={avgs}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </DataCats>
          </div>
          {data && (
            <>
              <div
                className={
                  selectedMetric || selectedMetric !== null
                    ? "flex transition-all duration-500 ease-in-out opacity-100"
                    : "flex transition-all duration-500 ease-in-out opacity-0"
                }
              >
                <div className="flex justify-between items-start w-full  pb-4 mr-1.5 gap-8 xs:gap-8 ">
                  <div className="w-full flex flex-col xs:flex-row items-start xs:items-center gap-4 xs:gap-8">
                    {data && (
                      <h4 className="w-fit text-h5 sm:text-h4 text-purple font-medium font-PhoenixSansMedium">{groupTitle[selectedMetric]}</h4>
                    )}
                    {metrics[selectedMetric] && (
                      <div className="h-fit bg-peach  p-1 font-medium font-PhoenixSansMedium text-[15px] leading-[15px] rounded-[4px] whitespace-nowrap">
                        Average:{" "}
                        {/* {selectedMetric === 5 ? `£${calculateAverage(metrics[selectedMetric])}` : `${calculateAverage(metrics[selectedMetric])}%`} */}
                        {avgs[selectedMetric]}
                      </div>
                    )}
                  </div>
                  <CloseButton
                    data={data}
                    comparisonOn={comparisonOn}
                    setComparisonOn={setComparisonOn}
                    hasTrue={hasTrue}
                    setSelectedMetric={setSelectedMetric}
                    resizeFunction={resizeFunction}
                  />
                </div>
              </div>
              <div
                ref={ref3b}
                className={
                  selectedMetric || selectedMetric !== null
                    ? "mt-3 absolute h-fit w-full transition-all duration-500 ease-in-out opacity-100"
                    : "mt-3 absolute h-fit w-full transition-all duration-500 ease-in-out opacity-0"
                }
              >
                <p className="text-darkGrey text-pLarge my-6">{groupDescription[selectedMetric]}</p>

                <DataCatsCompare>
                  {selectedMetric &&
                    comparisonOn.some(hasTrue) &&
                    Object.keys(data?.groups).map((index, key) => (
                      <div key={index} className={comparisonOn ? "block" : "hidden"}>
                        <ComparisonPanel
                          selectedMetric={selectedMetric}
                          allGroupData={allGroupData}
                          index={index}
                          groupTitle={groupTitle}
                          groupData={groupData}
                          groupDescription={groupDescription}
                          ageGroupIndex={ageGroupIndex}
                          setSelectedMetric={setSelectedMetric}
                          setComparisonOn={setComparisonOn}
                          comparisonOn={comparisonOn}
                          minMax={minmax[selectedMetric]}
                          metricData={metrics[selectedMetric]}
                        />
                      </div>
                    ))}
                </DataCatsCompare>
              </div>
            </>
          )}
        </AccordionBody>
      </Accordion>

      {/* Work sustainability outlook */}

      <Accordion
        className="accordion-header transition-all  mt-4 ease-in-out duration-500"
        open={open === 4}
        icon={<Icon id={4} open={open} />}
        animate={CUSTOM_ANIMATION}
      >
        <AccordionHeader
          className="flex justify-start border-b-0  transition-all ease-in-out duration-500 -mb-3"
          onClick={() => {
            handleOpen(4);
            resizeFunction();
            setTimeout(() => {
              resizeFunction();
            }, 500);
          }}
        >
          {data && groupFirstInCat[13] === "TRUE" && (
            <h3 className="text-h4 sm:text-h3 text-purple font-PhoenixSansMedium font-medium mt-2 mb-2 mr-2">{groupCategory[13]}</h3>
          )}
          {data && groupFirstInCat[13] !== "TRUE" && <div></div>}
        </AccordionHeader>

        <AccordionBody
          style={{ height: selectedMetric === null ? accordionHeights + 40 : accordionHeights + 240 }}
          className="relative transition-all ease-in-out duration-500"
        >
          <div
            ref={ref4a}
            className={
              !selectedMetric || selectedMetric === null
                ? "transition-all duration-500 ease-in-out opacity-100"
                : "transition-all duration-500 ease-in-out opacity-0"
            }
          >
            {!comparisonOn.some(hasTrue) && (
              <p className="text-darkGrey text-pLarge pb-6">
                As society is living longer, we need to start thinking about working for longer. Yet, some people are choosing to leave the workforce
                before State Pension age. This can substantially limit the amount of pension savings that someone has to rely on in retirement. These
                metrics capture some of the factors that come into play in people’s ability to continue in the workforce – based on whether they can
                perform sufficient hours, progress if they want to, and have job security in the face of automation.
              </p>
            )}
            <DataCats>
              {data &&
                !comparisonOn.some(hasTrue) &&
                groupData.map((par, index) => {
                  if (index >= 13 && index < 16) {
                    return (
                      <ChartPanel
                        key={index}
                        index={index}
                        groupTitle={groupTitle}
                        groupData={groupData}
                        groupDescription={groupDescription}
                        ageGroupIndex={ageGroupIndex}
                        setSelectedMetric={setSelectedMetric}
                        setComparisonOn={setComparisonOn}
                        comparisonOn={comparisonOn}
                        minMax={minmax[index]}
                        allGroupData={allGroupData}
                        metricData={metrics[index]}
                        size={size}
                        resizeFunction={resizeFunction}
                        avgs={avgs}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
            </DataCats>
          </div>
          {data && (
            <>
              <div
                className={
                  selectedMetric || selectedMetric !== null
                    ? "flex transition-all duration-500 ease-in-out opacity-100"
                    : "flex transition-all duration-500 ease-in-out opacity-0"
                }
              >
                <div className="flex justify-between items-start w-full  pb-4 mr-1.5 gap-8 xs:gap-8 ">
                  <div className="w-full flex flex-col xs:flex-row items-start xs:items-center gap-4 xs:gap-8">
                    {data && (
                      <h4 className="w-fit text-h5 sm:text-h4 text-purple font-medium font-PhoenixSansMedium">{groupTitle[selectedMetric]}</h4>
                    )}
                    {metrics[selectedMetric] && (
                      <div className="h-fit bg-peach  p-1 font-medium font-PhoenixSansMedium text-[15px] leading-[15px] rounded-[4px] whitespace-nowrap">
                        Average:{" "}
                        {/* {selectedMetric === 5 ? `£${calculateAverage(metrics[selectedMetric])}` : `${calculateAverage(metrics[selectedMetric])}%`} */}
                        {avgs[selectedMetric]}
                      </div>
                    )}
                  </div>
                  <CloseButton
                    data={data}
                    comparisonOn={comparisonOn}
                    setComparisonOn={setComparisonOn}
                    hasTrue={hasTrue}
                    setSelectedMetric={setSelectedMetric}
                    resizeFunction={resizeFunction}
                  />
                </div>
              </div>
              <div
                ref={ref4b}
                className={
                  selectedMetric || selectedMetric !== null
                    ? "mt-3 absolute h-fit w-full transition-all duration-500 ease-in-out opacity-100"
                    : "mt-3 absolute h-fit w-full transition-all duration-500 ease-in-out opacity-0"
                }
              >
                <p className="text-darkGrey text-pLarge my-6">{groupDescription[selectedMetric]}</p>

                <DataCatsCompare>
                  {selectedMetric &&
                    comparisonOn.some(hasTrue) &&
                    Object.keys(data?.groups).map((index, key) => (
                      <div key={index} className={comparisonOn ? "block" : "hidden"}>
                        <ComparisonPanel
                          selectedMetric={selectedMetric}
                          allGroupData={allGroupData}
                          index={index}
                          groupTitle={groupTitle}
                          groupData={groupData}
                          groupDescription={groupDescription}
                          ageGroupIndex={ageGroupIndex}
                          setSelectedMetric={setSelectedMetric}
                          setComparisonOn={setComparisonOn}
                          comparisonOn={comparisonOn}
                          minMax={minmax[selectedMetric]}
                          metricData={metrics[selectedMetric]}
                        />
                      </div>
                    ))}
                </DataCatsCompare>
              </div>
            </>
          )}
        </AccordionBody>
      </Accordion>
    </div>
  );
};

export default AgeGroupPanel;
